import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import CryptoJS from 'crypto-js';
import cn from 'clsx';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { loginProject, testConnection } from '../../../redux/modules/blynk/actions';
import styles from './styles.module.scss';
import { required } from '../../../utils/validation';

const ErrorString = meta => (meta.error && meta.touched ? <div className={styles.error}>{meta.error}</div> : null);

const InputGroupField = ({ input, meta, ...props }) => (
    <>
        <InputGroup {...{ ...input, ...props }} />
        <ErrorString {...meta} />
    </>
);

export class ConnectionPage extends React.Component {
    state = {
        busy: false,
        connectionError: null,
    };

    async componentDidMount() {
        const { history, testConnection } = this.props;
        try {
            await testConnection();

            history.push('project');
        } catch (e) {
            // continue if fail
        }
    }

    handleSubmit = async params => {
        const { history, testConnection, loginProject } = this.props;

        const hash = CryptoJS.algo.SHA256.create();
        hash.update(params.password, 'utf-8');
        hash.update(CryptoJS.SHA256(params.email.toLowerCase()), 'utf-8');
        const final = hash.finalize();
        const finalPass = final.toString(CryptoJS.enc.Base64);

        this.setState({ busy: true });

        try {
            await loginProject(params.email, finalPass);
        } catch (e) {
            const error = 'Email hoặc mật khẩu không đúng';
            this.setState({ busy: false, connectionError: error });
            return;
        }

        try {
            await testConnection();

            history.push('project');
        } catch (e) {
            const error = e.response ? e.response.text : e.message;
            this.setState({ busy: false, connectionError: error });
        }
    };

    render() {
        const { busy, connectionError } = this.state;
        const { tokens, serverHost, serverPort, connectionMode } = this.props;

        return (
            <div className={cn('bp3-dark', styles.main)}>
                <div className={styles.emptyFiller} />
                <Form
                    onSubmit={this.handleSubmit}
                    initialValues={{
                        tokens: tokens.join('\n'),
                        serverHost,
                        serverPort,
                        connectionMode,
                    }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={cn('bp3-card', styles.form)}>
                            <div className={styles.title}>Huỳnh Phát Auto</div>
                            <FormGroup label="Email đăng nhập" labelFor="email-input">
                                <Field name="email" id="email-input" component={InputGroupField} validate={required} />
                            </FormGroup>
                            <FormGroup label="Mật khẩu" labelFor="password-input">
                                <Field
                                    name="password"
                                    id="password-input"
                                    type="password"
                                    component={InputGroupField}
                                    validate={required}
                                />
                            </FormGroup>

                            <Button
                                type="submit"
                                disabled={busy}
                                icon="log-in"
                                fill
                                className={styles.submitButton}
                                intent={Intent.PRIMARY}
                            >
                                {busy ? 'Đang đăng nhập...' : 'Đăng nhập'}
                            </Button>

                            {connectionError && (
                                <div className={styles.connectionProblem}>Đăng nhập thất bại ({connectionError})</div>
                            )}
                        </form>
                    )}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        tokens: state.blynk.get('tokens'),
        serverHost: state.blynk.get('serverHost'),
        serverPort: state.blynk.get('serverPort'),
        connectionMode: state.blynk.get('connectionMode'),
    };
}

export default connect(mapStateToProps, {
    testConnection,
    loginProject,
})(ConnectionPage);
